#home {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  position: relative;
  overflow: hidden;

  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin: 0 2rem;

  position: absolute;
  z-index: 1;

  h1 {
    font-size: 12rem;

    &::first-letter {
      margin-left: -0.04em;
    }

    @media screen and (max-width: 1200px) {
      font-size: 10rem;
    }

    @media screen and (max-width: 450px) {
      font-size: 5rem;
      margin-left: 1rem;
    }
  }

  h2 {
    font-size: 3.95rem;
    font-weight: 100;
    color: rgb(61, 61, 61);

    @media screen and (max-width: 1200px) {
      font-size: 3.25rem;
    }

    @media screen and (max-width: 450px) {
      font-size: 1.67rem;
      padding-left: 1rem;
    }
  }

  h3 {
    font-size: 2.3rem;
    font-weight: 100;
    color: rgb(61, 61, 61);

    @media screen and (max-width: 1200px) {
      font-size: 1.9rem;
    }

    @media screen and (max-width: 450px) {
      font-size: 0.97rem;
      padding-left: 1rem;
    }
  }

  h1,
  h2,
  h3 {
    line-height: 90%;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app__header-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app__header-container {
  width: 100%;
  display: flex;

  .app__header-container-left {
    display: flex;
    flex-direction: column;
  }

  .app__header-container-right {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 1200px) {
      display: none;
    }

    .circle-header {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      flex-direction: row;

      width: 90px;
      height: 90px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.app__header-right-slide {
  display: flex;
  right: 0;
  background-color: var(--primary-color);
  height: 90vh;
  width: 70%;
  position: absolute;
  -webkit-clip-path: polygon(55% 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(55% 0, 100% 0, 100% 100%, 0% 100%);

  @media screen and (max-width: 450px) {
    position: relative;
    width: 100%;
    -webkit-clip-path: polygon(82% 0, 100% 0, 100% 100%, 11% 100%);
    clip-path: polygon(82% 0, 100% 0, 100% 100%, 11% 100%);
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    border-radius: 15px;
    flex-direction: row;
    width: auto;

    @media screen and (max-width: 450px) {
      padding: 0;
    }
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 100%;
      text-transform: uppercase;
      text-align: right;
    }
  }

  span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
